<template>
   <div class="container bg-white width-fix margin-fix">
    <div class="row mt-4">
      <div class="col-12 text-center">
        <span class="payment-approve-roundoff-block mt-0">
          <svg
            class="payment-approve-icon"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 50 62.5"
            xml:space="preserve"
            width="100"
            height="100"
            fill="#1b9142"
          >
            <path
              d="M19,44c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2S19,45.1,19,44z M23,5h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1
	S23.6,5,23,5z M37.4,22.2l-5.3,4.4l-2.4-2.4c-0.4-0.4-1.1-0.3-1.4,0.1c-0.3,0.4-0.3,0.9,0,1.3l3,3c0.4,0.4,1,0.4,1.4,0.1l6-5
	c0.4-0.4,0.5-1,0.1-1.4C38.4,21.9,37.8,21.9,37.4,22.2L37.4,22.2z M44,25c0,5.1-3.9,9.4-9,10v11c0,1.7-1.3,3-3,3H10
	c-1.7,0-3-1.3-3-3V4c0-1.7,1.3-3,3-3h22c1.7,0,3,1.3,3,3v11.1C40.1,15.6,44,19.9,44,25z M9,9h24V4c0-0.6-0.4-1-1-1H10
	C9.4,3,9,3.4,9,4V9z M33,41H9v5c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V41z M33,35c-5.5-0.6-9.5-5.5-9-10.9c0.5-4.7,4.2-8.5,9-9V11H9
	v28h24V35z M42,25c0-4.4-3.6-8-8-8s-8,3.6-8,8s3.6,8,8,8S42,29.4,42,25z"
            />
          </svg>
        </span>
      </div>
    </div>

    <div v-if="(transactiondetails.free_spin_wheel_id && transactiondetails.free_spin_wheel_id != null ) && (rwInvited != 1 && rwState == 'allowed')" class="row mb-4">
      <div class="col-12 text-center payment-approved-text-div">
        <label class="payment-approved-text">{{ approve_label }}</label>
        <br />
        <label class="payment-approved-amount"
          >${{ finaltransactionAmount }}</label
        >
        <div class="row justify-content-center">
          <div class="spin-rward-card" @click="redirectToWheel">
            <svg width="40" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M71.1435 70.5881C73.1964 75.098 75.2586 79.5824 77.3114 84.0924C84.6743 100.103 92.0278 116.139 99.4163 132.159C99.6579 132.685 99.8646 133.226 100.168 133.978C97.4714 134.99 94.9187 136.085 92.2883 136.917C78.8721 141.246 65.389 141.507 51.8134 137.69C51.3855 137.591 50.9482 137.517 50.5015 137.468L46.6677 136.054C54.7357 114.189 62.7943 92.3493 70.8368 70.4749L71.1435 70.5881Z" fill="black"/>
            <path d="M95.0486 4.94183C87.0184 26.7044 78.9787 48.4925 70.9229 70.2456C70.8945 70.3224 70.8823 70.4342 70.8539 70.5111C70.5934 70.0367 70.2978 69.5786 70.0722 69.0881C60.8082 48.9436 51.5441 28.799 42.2801 8.65443C42.0706 8.19899 41.8867 7.75299 41.6156 7.07117C43.8504 6.20865 45.9414 5.26393 48.129 4.52942C61.0917 0.0913902 74.2254 -0.560182 87.5338 2.72158C88.9451 3.06781 90.3403 3.37899 91.726 3.71579L95.0486 4.94183Z" fill="black"/>
            <path d="M41.5178 7.0943C51.2008 28.1498 60.8743 49.2308 70.5572 70.2863C70.5894 70.3563 70.6472 70.4358 70.705 70.5153C70.1749 70.3779 69.6354 70.2661 69.1242 70.0775C48.345 62.41 27.5657 54.7426 6.78639 47.0751C6.32633 46.9054 5.87572 46.71 5.19508 46.4298C6.15424 44.2239 6.99894 42.0921 7.99699 40.0169C14.0242 27.6964 22.8733 17.9589 34.6116 10.8582C35.8423 10.1197 37.057 9.34622 38.2972 8.58211L41.5178 7.0943Z" fill="#0DD668"/>
            <path d="M7.29107 99.8554C28.3298 90.1361 49.394 80.4262 70.4327 70.7069C70.5027 70.6745 70.5822 70.6166 70.6616 70.5586C70.5238 71.0896 70.4115 71.6299 70.2226 72.142C62.5419 92.9572 54.8612 113.772 47.1804 134.588C47.0104 135.048 46.8148 135.5 46.5341 136.182C44.3322 135.224 42.2042 134.38 40.1328 133.383C27.8352 127.362 18.1204 118.512 11.0423 106.767C10.3062 105.535 9.53502 104.32 8.77331 103.079L7.29107 99.8554Z" fill="#D3FCC8"/>
            <path d="M100.292 134.024C90.6092 112.968 80.9357 91.8872 71.2528 70.8318C71.2205 70.7617 71.1628 70.6822 71.105 70.6027C71.635 70.7401 72.1746 70.8519 72.6857 71.0406C93.465 78.708 114.244 86.3755 135.024 94.0429C135.484 94.2127 135.934 94.408 136.615 94.6883C135.656 96.8942 134.811 99.026 133.813 101.101C127.786 113.422 118.937 123.159 107.198 130.26C105.968 130.998 104.753 131.772 103.513 132.536L100.292 134.024Z" fill="#0DD668"/>
            <path d="M134.331 41.2265C113.293 50.9458 92.2284 60.6557 71.1736 70.34C71.1036 70.3724 71.0242 70.4303 70.9447 70.4882C71.0825 69.9573 71.1948 69.417 71.3838 68.9049C79.0645 48.0897 86.7452 27.2745 94.4514 6.46867C94.6215 6.00782 94.8171 5.5564 95.0978 4.87454C97.2997 5.8325 99.4277 6.67591 101.499 7.67295C113.797 13.6942 123.511 22.544 130.59 34.2898C131.326 35.5213 132.097 36.7368 132.859 37.9778L134.331 41.2265Z" fill="#D3FCC8"/>
            <path d="M5.1902 46.5153C26.9151 54.5317 48.6657 62.5575 70.3812 70.5995C70.4578 70.6278 70.5695 70.6399 70.6462 70.6682C70.1722 70.9297 69.7144 71.2261 69.2243 71.4526C49.0957 80.7514 28.9672 90.0503 8.83869 99.3491C8.38361 99.5593 7.93798 99.744 7.25675 100.016C6.39787 97.7794 5.45675 95.6868 4.72566 93.4972C0.308002 80.5224 -0.327974 67.3722 2.96442 54.0425C3.31178 52.629 3.62413 51.2316 3.96204 49.8437L5.1902 46.5153Z" fill="#007EE5"/>
            <path d="M136.29 94.6281C135.846 94.4933 135.428 94.368 134.993 94.2077C114.061 86.4837 93.128 78.7596 72.2209 71.045C71.8375 70.9036 71.4636 70.7365 71.0896 70.5694C71.4236 70.3727 71.7764 70.1247 72.1265 69.963C92.4045 60.5739 112.699 51.2197 132.967 41.8562C133.282 41.7107 133.623 41.5745 134.094 41.3993C134.281 41.7591 134.502 42.1027 134.663 42.453C140.335 55.5706 141.949 69.1398 139.288 83.1971C138.709 86.1832 137.916 89.1194 137.235 92.0678L136.29 94.6281Z" fill="#29576C"/>
            </svg>
            <span>FREE SPIN AWARDED</span>
            <svg width="17" viewBox="0 0 49 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46.6265 22.0146L29.509 39.4962C28.5806 40.4444 27.0559 40.4604 26.1077 39.532C25.1596 38.6036 25.1435 37.0789 26.0719 36.1308L39.1277 22.7973L2.88655 23.1787C1.53777 23.1929 0.471099 22.1484 0.456906 20.7997C0.442712 19.4509 1.48717 18.3842 2.83595 18.37L39.0771 17.9886L25.7436 4.9329C24.7955 4.00449 24.7794 2.47979 25.7078 1.53163C26.172 1.05756 26.756 0.816816 27.4011 0.810027C28.0461 0.803239 28.635 1.03164 29.1091 1.49584L46.5907 18.6134L48.3092 20.2961L46.6265 22.0146Z" fill="white"/>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="row mb-4">
      <div class="col-12 text-center payment-approved-text-div">
        <label class="payment-approved-text">{{ approve_label }}</label>
        <br />
        <label class="payment-approved-amount"
          >${{ finaltransactionAmount }}</label
        >
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class="tn-details">
          <div class="d-flex justify-content-between">
            <div class="tn-detail-label total">Transaction Total:</div>
            <div class="tn-detail-value total">${{ finaltransactionAmount }}</div>
          </div>
          <div v-if="rwState == 'allowed' || rwState == 'partially_allowed'" class="d-flex justify-content-between my-2">
            <div class="tn-detail-label point row mx-0 text-left">
              <span>Paid with Points</span>
              <span>
              ({{pointNumberFormatter(reward_point_used)}} 
              <svg style="margin-left: 1px;" width="18" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M40.7744 30.2796C42.9485 25.6613 42.0587 21.2453 41.8069 20.0739C41.5215 18.7507 40.7995 15.6241 38.1721 12.8178C36.9207 11.499 35.4344 10.4271 33.7902 9.65742C31.574 8.62083 29.6853 8.41857 28.6528 8.31744C24.3968 7.89607 20.8795 9.14334 18.9152 10.0367C19.8716 9.11677 20.9648 8.35195 22.1555 7.76965C23.2077 7.26475 24.3231 6.90473 25.4713 6.69935C27.3513 6.33868 29.2787 6.30163 31.1711 6.5898C32.0441 6.72464 35.6873 7.3567 39.3221 10.1884C44.619 14.3094 46.7932 20.0317 45.5592 26.6136C43.6788 36.6846 34.0588 42.5838 24.3044 39.9882C21.4335 39.2212 18.4115 37.4093 17.0684 35.6395C17.1356 35.6395 17.2111 35.6227 17.2531 35.648C17.5889 35.8671 17.9163 36.1031 18.2604 36.3222C23.7168 39.7606 29.3075 39.7859 34.8647 36.7098C35.5683 36.3194 36.2368 35.8682 36.8625 35.3614C39.2717 33.4062 40.3714 31.1308 40.7744 30.2796Z" fill="black"/>
              <path d="M46.0208 30.8863C45.9621 31.2403 45.8613 31.746 45.7018 32.3443C44.5434 36.6255 41.8992 39.3307 40.7659 40.46C36.6191 44.598 31.7922 45.7947 29.5845 46.3172C25.0431 47.3875 21.3747 46.9324 20.0568 46.7217C15.3139 45.9632 12.0569 44.0417 11.058 43.4097C9.01996 42.125 7.19333 40.53 5.64353 38.6818C3.83349 36.5321 2.41547 34.0786 1.4547 31.4341C1.21127 30.7599 0.287876 28.1137 0.0528318 24.4898C-0.165424 21.0345 0.355032 18.4557 0.480949 17.8574C0.891485 15.9026 1.55466 14.01 2.45364 12.2278C2.92373 11.3176 5.31615 6.86784 10.6047 3.54738C11.8302 2.77205 15.0369 0.951698 19.5783 0.260639C20.7787 0.0752328 31.1291 -1.35745 38.5414 5.15704C41.9747 8.17411 43.9978 12.2109 44.3168 12.8598C44.8079 13.8622 45.2341 14.8953 45.5927 15.9527C45.4752 15.7926 44.6022 14.5622 44.577 14.5285C41.3535 9.7332 37.6096 7.47462 37.6096 7.47462C36.1939 6.63968 34.6718 6.00177 33.085 5.57842C28.0399 4.21316 23.7923 5.42673 22.785 5.73012C16.8753 7.50833 13.3748 12.2362 13.1734 12.5143C9.60571 17.4528 9.68126 22.6105 9.74842 24.0432C9.97507 28.8637 12.0737 32.2685 13.0307 33.6422C13.1062 33.7349 13.2153 33.8781 13.3496 34.0467C13.4504 34.1815 13.5511 34.3164 13.6518 34.4512C16.1534 37.7548 19.4692 40.0555 23.1544 41.2017C25.9006 42.0448 28.8026 42.2462 31.6383 41.7904C34.474 41.3347 37.1683 40.2339 39.5151 38.5723C41.5802 37.1059 42.8309 35.5805 43.6032 34.6366C44.6273 33.3893 45.3745 32.1589 45.6179 31.6701C45.6515 31.6111 46.004 30.8863 46.0208 30.8863Z" fill="#007EE5"/>
              <path d="M19.0326 30.9623C14.6004 30.9623 12.3842 28.0211 12.3842 23.5123C12.3842 18.8519 14.7179 16.0455 19.2845 16.0455C21.0473 16.0455 22.466 16.4669 23.5572 17.3602C24.5814 18.2788 25.1522 19.5261 25.2949 21.1105H23.5153C22.8773 21.1105 22.4156 20.8155 22.1386 20.234C21.6517 19.1974 20.6947 18.6665 19.2929 18.6665C16.5563 18.6665 15.3391 20.6048 15.3391 23.5208C15.3391 26.3524 16.5059 28.3497 19.2005 28.3497C21.0473 28.3497 22.105 27.33 22.424 25.7288H25.2865C25.0263 29.0661 22.6842 30.9623 19.0326 30.9623Z" fill="black"/>
              <path d="M34.3357 16.0792H29.3075C28.4596 16.0792 27.7881 16.8208 27.7881 17.672V30.9116H30.8101V25.6276H34.554C37.5172 25.6276 39.1038 23.7735 39.1038 20.8491C39.1038 17.7647 37.4753 16.0792 34.3357 16.0792ZM33.9916 22.9476H31.0451V18.6496H34.1427C35.5529 18.6496 36.2748 19.3659 36.2748 20.807C36.2832 22.2481 35.5277 22.9729 33.9916 22.9476Z" fill="#007EE5"/>
              </svg>
              ):</span></div>
            <div class="tn-detail-value point">${{reward_amount_used}}</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="tn-detail-label bank">Paid with Bank:</div>
            <div class="tn-detail-value bank">${{consumer_bank_posting_amount}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center store-name-row">
      <div class="col-12 col-md-6 store-name-div p-0 mt-5">
        <label class="storename-box store-name-text">{{
          transactiondetails.store_name
        }}</label>
      </div>
    </div>
    <!-- need to enable once v2 tipping is started again -->
    
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 p-0">
        <div class="row tip-btn-row">
          <div class="col-12 text-center">
            <button
              @click="clickOK"
              class="btn btn-danger btn-md center-block tip-ok-btn w-100 tip-btn mt-4"
            >
              <span class="forgetpassword-ok-label">OK</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Leave a tip modal start -->
    <div>
      <b-modal
        ref="my-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="tip-modal-center"
        centered
        title="BootstrapVue"
        class="hsf"
      >
        <div class="row cross-btn-div">
          <button @click="hideModal" class="cross-btn">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#ffffff"
              height="30"
              width="30"
            >
              <g>
                <path
                  d="M85.7,14.3c-2.3-2.3-6.1-2.3-8.5,0L50,41.5L22.7,14.3c-2.3-2.3-6.1-2.3-8.5,0c-2.3,2.3-2.3,6.1,0,8.5L41.5,50L14.3,77.3
		c-2.3,2.3-2.3,6.1,0,8.5c1.2,1.2,2.7,1.8,4.2,1.8s3.1-0.6,4.2-1.8L50,58.5l27.3,27.3c1.2,1.2,2.7,1.8,4.2,1.8s3.1-0.6,4.2-1.8
		c2.3-2.3,2.3-6.1,0-8.5L58.5,50l27.3-27.3C88.1,20.4,88.1,16.6,85.7,14.3z"
                />
              </g>
            </svg>
          </button>
        </div>
        <div class="row">
          <svg
            class="dollar-icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            height="40"
            width="40"
            fil="#ffffff"
            fill="#ffffff"
          >
            <g id="Layer_2" />
            <g id="Layer_1">
              <g>
                <path
                  d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4
			c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z
			 M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8l0,0c0.1-0.5,2.7-1.8,7.5-1.8s7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"
                />
                <path
                  d="M5268.4,2410.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1s-0.4-1-1-1H5268.4z"
                />
                <path
                  d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z"
                />
                <path
                  d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z"
                />
              </g>
              <g>
                <path
                  d="M46.3,32.2h7.4c4,0,7.2,3.3,7.2,7.2h6.6c0-7.6-6.2-13.8-13.9-13.8h-0.4v-7.8h-6.6v7.8h-0.4c-7.6,0-13.9,6.2-13.9,13.8
			s6.2,13.8,13.9,13.8h7.4c4,0,7.2,3.2,7.2,7.2s-3.2,7.2-7.2,7.2h-7.4c-4,0-7.2-3.3-7.2-7.2h-6.6c0,7.6,6.2,13.8,13.9,13.8h0.4V82
			h6.6v-7.8h0.4c7.6,0,13.9-6.2,13.9-13.8s-6.2-13.8-13.9-13.8h-7.4c-4,0-7.2-3.3-7.2-7.2S42.3,32.2,46.3,32.2z"
                />
                <path
                  d="M50,2.5C23.8,2.5,2.5,23.8,2.5,50S23.8,97.5,50,97.5S97.5,76.2,97.5,50S76.2,2.5,50,2.5z M50,90.9
			C27.5,90.9,9.1,72.5,9.1,50S27.5,9.1,50,9.1S90.9,27.5,90.9,50S72.5,90.9,50,90.9z"
                />
              </g>
            </g>
          </svg>
          <label class="leave-a-tip-modal-label">Leave a TIP</label>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="t-div"
              @click="selectTip(15)"
              :class="{ 'folder-selected': selected == 1 }"
              data-dismiss="modal"
            >
              <h5 class="h4-style">15%</h5>
              <h5 class="h4-style1">${{ tipamountFor15 }}</h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="t-div"
              @click="selectTip(20)"
              :class="{ 'folder-selected': selected == 2 }"
              data-dismiss="modal"
            >
              <h5 class="h4-style">20%</h5>
              <h5 class="h4-style1">${{ tipamountFor20 }}</h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="t-div"
              @click="selectTip(25)"
              :class="{ 'folder-selected': selected == 3 }"
              data-dismiss="modal"
            >
              <h5 class="h4-style">25%</h5>
              <h5 class="h4-style1">${{ tipamountFor25 }}</h5>
            </div>
          </div>
        </div>
        <div class="row custom-tip-div">
          <div class="col-12">
            <div
              class="input-group"
              :class="{ 'folder-selected': selected == 4 }"
            >
              <input
                class="form-control Custom-Tip"
                v-model="customTipAmount"
                inputmode="numeric"
                :placeholder="customplaceholder"
                style="height:50px; color: #000000; padding-right: 20px; !important;"
                @keypress="isNumber($event)"
              />
              <button
                type="button"
                class="btn3 btn-dark"
                data-dismiss="modal"
                :disabled="disableButton == false"
                @click="clickADD"
                style="
                  z-index: 5;
                  margin-left: -10px;
                  border-radius: 8px !important;
                "
              >
                ADD
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="purchase-power-modal-text"
              >$ {{ transactionAmount }} Purchase</label
            >
          </div>
        </div>
      </b-modal>
    </div>
    <!-- MODAL FOR TRANSACTION ERROR MESSAGES -->
    <div>
      <b-modal
        ref="transaction-error-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="transaction-error-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ error_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideTransactionErrorModal"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- MODAL FOR TERMINAL TIP NOT ALLOWED -->
    <div>
      <b-modal
        ref="tip-not-allowed-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="tip-not-allowed-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ tip_error_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideTipErrorModal"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!----------------------------------- The Modal ---------------------------->

    <!-- //////////// INVITAION MODAL //////////// -->
    <b-modal
    ref="invitation-modal"
    hide-footer
    v-b-modal.modal-center
    no-close-on-backdrop
    modal-backdrop
    hide-header
    id="invitation-modal"
    centered
    title="BootstrapVue"
    >
    <InvitationComponent :modal="$refs['invitation-modal']">
      <template v-slot:closeModal="">
        <a class="close-modal" @click="hideInviteModal('invitation-modal')" href="javascript:void(0)">
          <svg fill="#000000" width="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.293,18.293,10.586,12,4.293,5.707A1,1,0,0,1,5.707,4.293L12,10.586l6.293-6.293a1,1,0,1,1,1.414,1.414L13.414,12l6.293,6.293a1,1,0,1,1-1.414,1.414L12,13.414,5.707,19.707a1,1,0,0,1-1.414-1.414Z"/></svg>
        </a>
      </template>
    </InvitationComponent>
    </b-modal>
  </div>
</template>

<script>
import api from "../../api/payment.js";
import InvitationComponent from '../InvitationComponent.vue';
import rewardwheelapi from "../../api/rewardwheel.js";
import $store from '../../store'

export default {
  name: "Tip",
  data() {
    return {
      customplaceholder: "Custom TIP",
      show: 0,
      refreshing: false,
      registration: null,
      transactionAmount: "",
      finaltransactionAmount: "",
      reward_point_used: 0,
      reward_amount_used: 0,
      consumer_bank_posting_amount: 0,
      transactionID: "",
      tipamountFor15: "",
      tipamountFor20: "",
      tipamountFor25: "",
      selected: -1,
      tipamount: "",
      customTipAmount: "",
      disableButton: false,
      showTipTransaction: false,
      finalTipamount: "",
      enableTip: 0,
      approve_label: "",
      error_message: "",
      tip_error_message: "",
      currentUser: localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null,
      rwState: '',
      rwInvited: 0,
      invitationModalShown: false,
      ignoreInvitation: false
    };
  },
  props: {
    transactiondetails: {},
  },
  components: {
    InvitationComponent
  },
  watch: {
    tipamount: function (newval, oldval) {
      if (this.tipamount.length > 0) {
        this.disableButton = true;
      }
    },
    customTipAmount: function (newval, oldval) {
      this.tipamount = this.customTipAmount;
    },
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    hideInviteModal(modal) {
      this.$refs[modal].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    clickOK() {

      const consumer_login_response = JSON.parse(localStorage.getItem("consumer_login_response"));

      if(consumer_login_response.rw_state  == 'not_allowed' 
      && consumer_login_response.rw_invited  == 0
      && consumer_login_response.rw_complete_shutdown == 0
      && consumer_login_response.rw_partial_shutdown == 0
      && consumer_login_response.rw_invitation_state == 1
      && this.ignoreInvitation == false
      ){
        rewardwheelapi
        .inviteConusmerForRewardWheelAfterTransaction()
        .then((response) => {

          if(response == 1){

            if(this.invitationModalShown == false){
              consumer_login_response.rw_invited = 1
              localStorage.setItem(
                "consumer_login_response",
                JSON.stringify(consumer_login_response)
              );
              const event = new CustomEvent('rwStateCahnged', {detail:consumer_login_response});
              document.dispatchEvent(event);

              this.invitationModalShown == true
            }
          }else{
            this.$root.$emit("showSpinInfo", {show_spin_info: true});
            this.$router
            .push({ path: '/pay' })
            // .then(() => { this.$router.go() })
          }
        })
        .catch(function (error) {
        });
      }else{
        this.$root.$emit("showSpinInfo", {show_spin_info: true});
        this.$router.push("/pay");
      }
    },
    redirectToWheel(){
      this.$root.$emit("showSpinInfo", {show_spin_info: true});
      if(this.transactiondetails.free_spin_wheel_id && this.transactiondetails.free_spin_wheel_id != ''){
        if(this.rwInvited == 1){
            this.$refs['invitation-modal'].show();
        }else{
          $store.commit('setRewardWheelID', this.transactiondetails.free_spin_wheel_id)
          this.$router.push("/rewardWheel");
        }
      }else{
        this.$router.push("/pay");
      }
    },
    clickADD() {
      this.payTip();
    },
    payTip() {
      if (this.tipamount.length > 0) {
        this.makeTransactionForTip();
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      // first number should't be dot
      if(this.customTipAmount.length == 0 && charCode == 46){
        this.customTipAmount = 0.00;
      }
      // only allow positive number
      if ((charCode < 48 || charCode > 57) && (charCode !== 46 || this.customTipAmount.indexOf('.') !== -1)) { // 46 is dot
          evt.preventDefault();
      }
      // restrict to 2 decimal place
      else if (this.customTipAmount !== null && this.customTipAmount.indexOf('.') > -1 && (this.customTipAmount.split('.')[1].length > 1)) {
        evt.preventDefault();
      }
      else {
        return true;
      }
    },
    selectTip(amount) {
      switch (amount) {
        case 15:
          this.selected = 1;
          this.tipamount = this.tipamountFor15;
          this.payTip();
          break;
        case 20:
          this.selected = 2;
          this.tipamount = this.tipamountFor20;
          this.payTip();
          break;
        case 25:
          this.selected = 3;
          this.tipamount = this.tipamountFor25;
          this.payTip();
          break;
      }
    },
    makeTransactionForTip() {
      let self = this;
      var header = {
        "Content-type": "application/json",
      };
      var request = {
        transaction_id: this.transactionID,
        tip_amount: this.tipamount,
      };
      api
        .insertTip(request)
        .then((response) => {
          if (response.code == 200) {
            self.showTransactionErrorModal(response.message);
            this.hideModal();
            this.finaltransactionAmount = (
              parseFloat(response.data.amount) +
              parseFloat(response.data.tip_amount)
            ).toFixed(2);
            this.finalTipamount = response.data.tip_amount;
            this.showTipTransaction = true;
            this.enableTip = 0;
          } else {
            self.showTransactionErrorModal(response.message);
          }
        })
        .catch((err) => {
          self.showTipErrorModal(err.response.data.message);
        });
    },
    showTransactionErrorModal(msg) {
      this.error_message = msg;
      this.$refs["transaction-error-modal"].show();
    },
    hideTransactionErrorModal() {
      this.error_message = "";
      this.$refs["transaction-error-modal"].hide();
    },
    showTipErrorModal(msg) {
      this.tip_error_message = msg;
      this.hideModal();
      this.$refs["tip-not-allowed-modal"].show();
    },
    hideTipErrorModal() {
      this.tip_error_message = "";
      this.$refs["tip-not-allowed-modal"].hide();
      this.$router.push("/pay");
    },
  },

  mounted: function () {

    const consumer_login_response = JSON.parse(localStorage.getItem("consumer_login_response"));
    this.rwState = consumer_login_response.rw_state
    this.rwInvited = consumer_login_response.rw_invited

    document
      .getElementsByClassName("content-wrap")[0]
      .style.setProperty("background-color", "#ffffff");
    this.enableTip = this.transactiondetails.tip_allowed;
    this.$root.$emit("changeWhiteBackground", [true, true, "common"]);
    if (Number(this.transactiondetails.tip_amount) > 0) {
      this.finalTipamount = this.transactiondetails.tip_amount;
      this.showTipTransaction = true;
      this.finaltransactionAmount = (
        parseFloat(Number(this.transactiondetails.amount)) +
        parseFloat(Number(this.transactiondetails.tip_amount))
      ).toFixed(2);
    } else {
      this.finaltransactionAmount = Number(
        this.transactiondetails.amount
      ).toFixed(2);
    }
    this.reward_point_used = Number(
        this.transactiondetails.reward_point_used
      ).toFixed(2);
    this.reward_amount_used = Number(
        this.transactiondetails.reward_amount_used
      ).toFixed(2);
    this.consumer_bank_posting_amount = Number(
        this.transactiondetails.consumer_bank_posting_amount
      ).toFixed(2);

    this.transactionAmount = Number(this.transactiondetails.amount).toFixed(2);
    this.transactionID = this.transactiondetails.transaction_id;
    this.tipamountFor15 = ((this.transactionAmount * 15) / 100).toFixed(2);
    this.tipamountFor20 = ((this.transactionAmount * 20) / 100).toFixed(2);
    this.tipamountFor25 = ((this.transactionAmount * 25) / 100).toFixed(2);

    //changing the text
    this.approve_label =
      this.showTipTransaction != true
        ? "Payment Approved"
        : "Purchase Approved";

    this.$intercom.update({
      hide_default_launcher: true
    });

    // Set Invitation Modal 
    if(consumer_login_response.rw_state  == 'not_allowed' 
    && consumer_login_response.rw_invited  == 0
    && consumer_login_response.rw_complete_shutdown == 0
    && consumer_login_response.rw_partial_shutdown == 0
    && consumer_login_response.rw_invitation_state == 1
    ){
      rewardwheelapi
      .inviteConusmerForRewardWheelAfterTransaction()
      .then((response) => {
        if(response == 1){
          var self = this;
          setTimeout(function(){
            if(self.invitationModalShown == false){
              consumer_login_response.rw_invited = 1
              localStorage.setItem(
                "consumer_login_response",
                JSON.stringify(consumer_login_response)
              );
              const event = new CustomEvent('rwStateCahnged', {detail:consumer_login_response});
              document.dispatchEvent(event);

              self.invitationModalShown == true
            }
          }, 2000)
        }
      })
      .catch(function (error) {
      });
    }

    document.addEventListener('ignoreInvitationCahnged', (event) => {
        this.ignoreInvitation = event.detail.ignoreInvitation
    });
  },
  beforeDestroy() {
    // Show the launcher again
    this.$intercom.update({
      hide_default_launcher: false,
    });
  },
};
</script>

<style lang="scss">
#tip-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #149240;
}
#tip-modal-center___BV_modal_body_ {
  background-color: #149240 !important;
  border-radius: 12px;
  margin: 10px;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 15px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  padding: 5px !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 15px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  padding: 5px !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 15px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  padding: 5px !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 15px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  padding: 5px !important;
}
#transaction-error-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#tip-not-allowed-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
.tn-detail-label.total{
  font-size: 22px;
  font-weight: 700;
}
.tn-detail-value.total{
  font-size: 22px;
  font-weight: 700;
}
.tn-detail-label.point{
  color: #007EE5;
  font-size: 17px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tn-detail-value.point{
  color: #007EE5;
  font-size: 17px;
  font-weight: 700;
}
.tn-detail-label.bank{
  font-size: 17px;
  font-weight: 700;
}
.tn-detail-value.bank{
  font-size: 17px;
  font-weight: 700;
}
.tip-black-btn{
    width: 140px;
    height: 60px;
    background-color: #000000 !important;
    border-color: #000000 !important;
    display: inline-block;
    vertical-align: top;
    border-radius: 7px;
}
.tip-black-btn span, .tip-btn span{
  font-size: 17px;
  font-weight: 500;
}
.tip-btn{
  height: 60px;
  border-radius: 7px;
}
.spin-rward-card{
  background: #007ee5;
  color: #fff;
  border-radius: 50px;
  padding: 0 15px 0 0;
  margin-bottom: 0;
  width: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.spin-rward-card span{
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 14px;
}
.width-fix{
  width:85vw;
}
.margin-fix{
  margin-top:36px;
}
</style>
